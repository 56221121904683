import { SvgIconProps } from "@mui/material";

const ShownieuwsLogo: React.FC<SvgIconProps> = (props) => {
  return (
    <svg width="177" height="40" viewBox="0 0 177 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2101_2187)">
        <path
          d="M52.7618 25.026C51.4173 25.026 50.4489 24.6738 49.5925 23.7094L50.5009 22.7171C51.0742 23.3732 51.9202 23.7253 52.7898 23.6694C53.9583 23.6694 54.6386 23.1092 54.6386 22.1448C54.6624 21.7676 54.5357 21.3964 54.2864 21.1124C54.0183 20.8631 53.6681 20.7207 53.302 20.7122L52.2496 20.5442C51.6103 20.4709 51.0128 20.1897 50.5489 19.7439C50.088 19.2219 49.8533 18.5381 49.8967 17.8431C49.8967 16.1104 51.0291 14.9219 52.8899 14.9219C53.9382 14.8661 54.9566 15.2814 55.667 16.0544L54.7986 17.0067C54.2953 16.4741 53.5813 16.1926 52.8498 16.2384C52.4236 16.1979 52.0006 16.3429 51.6888 16.6363C51.377 16.9297 51.2066 17.3432 51.2212 17.7711C51.204 18.1126 51.3269 18.4464 51.5613 18.6954C51.842 18.9468 52.1921 19.1072 52.5657 19.1556L53.5861 19.3237C54.2101 19.379 54.7983 19.6393 55.2588 20.064C55.7687 20.6171 56.0316 21.3538 55.9871 22.1048C55.9831 23.9335 54.6386 25.026 52.7618 25.026Z"
          fill="white"
        />
        <path
          d="M67.8279 24.942V20.5963H64.1945V24.942H62.8259V14.99H64.1865V19.2397H67.8199V14.99H69.1765V24.942H67.8279Z"
          fill="white"
        />
        <path
          d="M81.8096 23.9616C81.2132 24.6384 80.3547 25.0262 79.4526 25.0262C78.5505 25.0262 77.692 24.6384 77.0957 23.9616C76.2153 22.9852 76.2273 21.8807 76.2273 19.96C76.2273 18.0392 76.2273 16.9387 77.0957 15.9583C77.692 15.2815 78.5505 14.8937 79.4526 14.8937C80.3547 14.8937 81.2132 15.2815 81.8096 15.9583C82.6899 16.9347 82.666 18.0392 82.666 19.96C82.666 21.8807 82.6899 22.9852 81.8096 23.9616ZM80.7811 16.8787C80.4489 16.4877 79.9617 16.2624 79.4486 16.2624C78.9355 16.2624 78.4483 16.4877 78.1161 16.8787C77.6759 17.4109 77.5879 17.9672 77.5879 19.968C77.5879 21.9688 77.6759 22.525 78.1161 23.0532C78.4483 23.4442 78.9355 23.6695 79.4486 23.6695C79.9617 23.6695 80.4489 23.4442 80.7811 23.0532C81.2213 22.525 81.3094 21.9648 81.3094 19.968C81.3094 17.9712 81.2333 17.4109 80.7811 16.8787Z"
          fill="white"
        />
        <path
          d="M96.8877 24.942H95.6873L93.8825 17.9672L92.0738 24.942H90.8733L88.5364 14.99H89.969L91.5296 22.1049L93.3263 14.99H94.4387L96.2395 22.1049L97.7961 14.99H99.2407L96.8877 24.942Z"
          fill="white"
        />
        <path
          d="M111.061 24.942L106.924 17.9392V24.942H105.567V14.99H106.812L110.945 21.9768V14.99H112.306V24.942H111.061Z"
          fill="white"
        />
        <path d="M119.673 24.942V14.99H121.033V24.942H119.673Z" fill="white" />
        <path
          d="M128.4 24.942V14.99H134.123V16.3466H129.761V19.2517H133.482V20.5963H129.761V23.5855H134.123V24.942H128.4Z"
          fill="white"
        />
        <path
          d="M144.083 25.0261C142.258 25.0261 140.881 23.6415 140.881 21.5887V14.99H142.238V21.5166C142.238 22.8452 142.966 23.6695 144.087 23.6695C145.207 23.6695 145.947 22.8452 145.947 21.5166V14.99H147.304V21.6087C147.3 23.6415 145.903 25.0261 144.083 25.0261Z"
          fill="white"
        />
        <path
          d="M161.838 24.942H160.657L158.833 17.9672L157.02 24.942H155.839L153.502 14.99H154.935L156.492 22.1049L158.292 14.99H159.385L161.186 22.1049L162.742 14.99H164.175L161.838 24.942Z"
          fill="white"
        />
        <path
          d="M172.89 25.026C171.546 25.026 170.577 24.6739 169.721 23.7095L170.629 22.7171C171.202 23.3721 172.046 23.724 172.914 23.6695C174.087 23.6695 174.763 23.1092 174.763 22.1448C174.789 21.7674 174.662 21.3955 174.411 21.1124C174.145 20.8624 173.795 20.7199 173.43 20.7123L172.374 20.5442C171.736 20.4701 171.14 20.1889 170.677 19.7439C170.216 19.2219 169.982 18.5381 170.025 17.8431C170.025 16.1104 171.158 14.9219 173.018 14.9219C174.066 14.8672 175.084 15.2823 175.795 16.0544L174.927 17.0068C174.424 16.4741 173.71 16.1927 172.978 16.2385C172.548 16.1962 172.122 16.3427 171.808 16.6399C171.495 16.9371 171.326 17.3557 171.346 17.7871C171.329 18.1279 171.45 18.4611 171.682 18.7115C171.964 18.9621 172.316 19.1223 172.69 19.1717L173.707 19.3397C174.331 19.3924 174.92 19.653 175.379 20.08C175.892 20.6315 176.157 21.369 176.112 22.1208C176.112 23.9336 174.763 25.026 172.89 25.026Z"
          fill="white"
        />
        <path
          d="M23.022 0.22409L24.0545 3.42538C29.4779 4.75852 33.9123 8.64944 35.9392 13.8536H24.5026L20.0088 0L15.515 13.8416H4.07841C6.10532 8.63744 10.5397 4.74651 15.9632 3.41337L16.9956 0.212085C6.65091 1.78797 -0.732299 11.0687 0.0578997 21.5029C0.848099 31.937 9.54478 40 20.0088 40C30.4729 40 39.1695 31.937 39.9597 21.5029C40.7499 11.0687 33.3667 1.78797 23.022 0.212085V0.22409ZM2.93386 20.0081C2.93283 18.5472 3.11841 17.0921 3.48617 15.6783L12.7339 22.409L9.20447 33.2374C8.7643 32.8772 8.33612 32.4891 7.92796 32.0809C4.71774 28.8851 2.91946 24.5378 2.93386 20.0081ZM10.8051 34.3858L20.0088 27.6871L29.2125 34.3858C23.5948 37.9815 16.3988 37.9815 10.7811 34.3858H10.8051ZM32.0937 32.0809C31.6935 32.4811 31.2574 32.8812 30.8172 33.2374L27.2838 22.409L36.5315 15.6783C36.8992 17.0921 37.0848 18.5472 37.0838 20.0081C37.0961 24.539 35.2948 28.8864 32.0817 32.0809H32.0937Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2101_2187">
          <rect width="177" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShownieuwsLogo;
